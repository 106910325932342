import * as React from "react";
import styled from "styled-components/native";
import {ActivityIndicator, Platform} from "react-native";
import { format } from "date-fns";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Stack } from "native-base";
import * as Cache from "../services/cache";
import { useTranslation } from "../contexts/translations";
import {
  useStartupConfig,
  useStartupOrganization,
  useStartupUser,
  useStartupUserActions,
} from "../contexts/startup/hooks";
import { View, Text, Image, Icon } from "./Styled";
import PhotoWithZoom from "./PhotoWithZoom";
import PROFILE_STATUS, {
  BLOCKED,
  ProfileStatus,
} from "../constants/profile-status";
import PHOTO_STATUS, { PENDING, PhotoStatus, REJECTED, UNDER_REVIEW } from "../constants/photo-status";
import { PORTRAIT } from "../constants/photo-shape";
import QRCodeWithZoom from "./QRCodeWithZoom";
import useIsMobile from "../hooks/useIsMobile";
import { CardHolderContext } from "../contexts/card-holder";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import * as API from "../services/api";
import { useNavigation } from "@react-navigation/native";
import { MainStackParams } from "../navigation/types";
import { StackNavigationProp } from "@react-navigation/stack";
import { StandTemplate } from "../interfaces/standTemplate.interface";

type Card = { isMobile: boolean };
const Card = styled.View<Card>`
  ${Platform.OS === "web" && "border-radius: 15px;"}
  ${Platform.OS === "web" && "box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);"}
  ${Platform.OS === "android" && `elevation: 5`}
  width: 100%;
  height: 100%;
  margin: ${(props) => !props.isMobile ? "40px" : "0"} 20px 20px;
  align-self: center;
`;

type ComonBgColorProp = { bgColor: string };

type CardTop = { bgColor: string, isMobile: boolean };

const CardTop = styled.View<CardTop>`
  background: ${(props) => props.bgColor};
  padding: ${(props) => props.isMobile ? "80px 0 100px" : "30px 0 100px"}
  flex-grow: 1;
`;

const CardMiddle = styled.View<ComonBgColorProp>`
  flex-grow: 1;
  background: ${(props) => props.bgColor};
`;

const CardBottom = styled(Stack) <ComonBgColorProp>`
  background: ${(props) => props.bgColor};
  padding: 10px 20px;
`;


const PhotoWrapper = styled.View`
  margin: -100px auto 10px;
  background: transparent;
  background-color: #eee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

type StatusWrapper = {
  status?: ProfileStatus | PhotoStatus;
};
const StatusWrapper = styled.View<StatusWrapper>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 4px;
  background: ${(props) =>
    props.status === PROFILE_STATUS.ACTIVATED
      ? "rgb(0,130,59)" // green
      : props.status === PROFILE_STATUS.BLOCKED ||
        props.status === PROFILE_STATUS.EXPIRED ||
        props.status === PHOTO_STATUS.REJECTED
        ? "rgb(225,0,0)" // red
        : "rgb(0,100,255)" // blue
  };
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const SecurityHologramWrapper = styled.View`
  width: 40px;
  height: 40px;
  margin: 20px 20px 0 0;
  background: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

const SecurityHologram = styled.Image`
  width: 30px;
  height: 30px;
`;

const QRCodeWrapper = styled.View`
  background: #fff;
  border-radius: 10px;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 10px;
`;

const PERSON = "Person";
const CARDHOLDER = "Cardholder";
const DATE = "Date";
const COSTACCOUNT = "costAccount";
const PROFILENAME = "profileName";
const EXTERNALCOMPANY = "externalCompany";
const DEPARTMENT = "department";
const LOCATION = "location";
const CREATED = "created";

function DigitalIdComponent() {
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const {
    cropRatio,
    photoZoomToFit,
    theme,
    cardBrand = null,
    showSecurityAnimation = false,
    showBarcode = false
  } = useStartupConfig();
  const [{ data, isLoading, error }] = React.useContext(CardHolderContext);
  const { name: organizationName, logo: organizationLogo } =
    useStartupOrganization();
  const { t } = useTranslation();
  // const cardTheme = theme || DEFAULT_CARD_THEME;
  const { photo, barcodeSource, barcodeType, photoStatus, profileEndDate } = useStartupUser() || {};
  const [profileTheme, setProfileTheme] = React.useState<StandTemplate>();
  const user = useStartupUser();
  const { reload } = useStartupUserActions();
  const [preferredProfile, setPreferredProfile] = React.useState<string>("")
  const isMobile = useIsMobile();

  var moment = require('moment');

  React.useEffect(()=> {
    if(data) {
      profilesCall();
    } 
    (async () => {
      let CachePreference = await Cache.get("ProfilePreference");
      if(CachePreference) {
        setPreferredProfile(CachePreference);
      }

    })()
  },[data, user]);

  const digitalIdStatus =
    data?.personStatus === BLOCKED || data?.photoApproved
      ? data?.personStatus
      : photoStatus;
  const digitalIdStatusText =
    data?.personStatus === BLOCKED || data?.photoApproved
      ? data?.personStatus
      : photoStatus === REJECTED
        ? t("digital_id.status.photo_rejected")
        : photoStatus === UNDER_REVIEW ? t("digital_id.status.under_review")
          : photoStatus === PENDING ? t("digital_id.status.pending_approval")
            : photoStatus;

  const lowerFirst = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  let profilesCall = async () => {
    const resProfiles = await Cache.get("card");

    if(user?.profilePreference) {
      await Cache.set("ProfilePreference", user?.profilePreference)
    } else {
      if(resProfiles.profiles.length){
        setPreferredProfile(resProfiles?.profiles[0]?.profile.name);
        await Cache.set("ProfilePreference", resProfiles.profiles[0].profile.name)
        return;
      }
    }
  }

  React.useEffect(() => {
    (async () => {
      if (__DEV__) {
        console.log(user, 'user');
        console.log(data, 'data');
      }
    })()
  }, [])

  React.useEffect(() => {
    (async () => {
      const CACHE_AUTOLOCK = "CACHE_AUTOLOCK";

      const isAutoLock = await Cache.get(CACHE_AUTOLOCK);
    })()
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      reload()
      if(user?.archive)
      {
        navigation.navigate("StartupMessageScreen");
      }
    }, 3600000);
    return () => clearInterval(interval);
  }, [user]);

  function logoContainter() {
    return (
      <View $transparent alignItems="center">
        <Image
          resizeMode="contain"
          source={
            organizationLogo ?
              { uri: organizationLogo }
              : require("../assets/images/cardexchange-logo.png")
          }
          shape={PORTRAIT}
          style={{
            width: isMobile ? wp("70%") : 280,
            height: isMobile ? hp("8%") : 80
          }}
        />
      </View>
    )
  }

  function getPropertyValue(entity: string | undefined) {
    if (!entity) {
      return '';
    }

    var propertyName = entity?.substring(entity.indexOf(".") + 1);
    propertyName = lowerFirst(propertyName);
    if (entity.includes(PERSON)) {
      if (entity.includes(DATE)) {
        propertyName = propertyName.toLowerCase().toString();
        if (user![propertyName]) {
          return moment.utc(user![propertyName]).format("MMMM DD, YYYY");
        }
      }

      return user![propertyName] ?? '';
    } else if (entity?.includes(CARDHOLDER)) {
      if (user?.profilePreference) {
        var cardholder = user?.profiles?.find(profile => profile?.profile?.name == user?.profilePreference);
      }
      
      if (cardholder == null) {
        var cardholder = user?.profiles?.at(0);
      }

      switch (propertyName) {
        case PROFILENAME:
          return cardholder.profile?.name;
        case EXTERNALCOMPANY:
          return cardholder.externalCompany?.name;
        case COSTACCOUNT:
          return cardholder.costAccount?.name;
        case DEPARTMENT:
          return cardholder.department?.name;
        case LOCATION:
          return cardholder.location?.name;
      }

      if ((propertyName.includes(DATE) || propertyName === CREATED) && cardholder[propertyName]) {
        return moment.utc(cardholder[propertyName]).format("MMMM DD, YYYY");
      }
      
      return cardholder[propertyName] ?? '';
    }
  }

  function separateBeforeUppercase(entity: string): string | undefined {
    if (!entity) {
      return '';
    }

    entity = entity.substring(entity.indexOf(".") + 1);
    return entity.replace(/([A-Z])/g, (match, p1, offset) => {
      return offset > 0 && entity[offset - 1] !== ' ' ? ` ${p1}` : p1;
    });
  }

  return (
    <View $fullscreen $transparent justifyContent="center">
      {isLoading && (
        <View $transparent height={30} justifyContent="center">
          <ActivityIndicator
            accessible
            accessibilityLabel={t("accessibility.loading", {
              text: t("tabs.digital_id"),
            })}
          />
        </View>
      )}
      
      {!isLoading && !!data && (
        <Card isMobile={isMobile}>
          <CardTop bgColor={theme!.cardTop.background} isMobile={isMobile}>
            {cardBrand === "logo" ? logoContainter() : cardBrand === "name" ? (
              <View $transparent justifyContent="center" alignItems="center">
                <Text
                  $responsive={isMobile} size={isMobile ? 2 : 20}
                  $bold
                  adjustsFontSizeToFit
                  $textAlign="center"
                  color={theme!.cardTop.text}
                  numberOfLines={2}
                >
                  {organizationName}
                </Text>
              </View>
            ) : logoContainter()}
          </CardTop>
          <CardMiddle bgColor={theme!.cardMiddle.background}>
            <PhotoWrapper>
              {photo ? (
                <PhotoWithZoom
                  size={isMobile ? wp("70%") : 280}
                  source={{ uri: `data:image/jpeg;base64,${photo}` }}
                  ratio={cropRatio}
                  crop={photoZoomToFit}
                  roundedBorder
                />
              ) : (
                <View
                  width={200}
                  height={280}
                  justifyContent="center"
                  alignItems="center"
                  bgColor="rgba(225,225,225,0.8)"
                >
                  <Icon as={MaterialIcons} name="person" size={100} />
                </View>
              )}
              {digitalIdStatus && (
                <StatusWrapper
                  status={digitalIdStatus}
                >
                  <Text
                    $responsive={isMobile} size={isMobile ? 1.5 : 13}
                    color={theme!.cardMiddle.text}
                    $bold
                    $uppercase
                    $textAlign="center"
                  >
                    {digitalIdStatusText}
                  </Text>
                </StatusWrapper>
              )}
            </PhotoWrapper>
            <View spaceVertical={20} spaceHorizontal={20} flexDirection="row" $transparent justifyContent="space-between">
              <View $transparent width="50%">
                {theme?.firstPosition &&
                  <Text $responsive={isMobile} size={isMobile ? 2.2 : 20} color={theme!.cardMiddle.text} $bold>{`${getPropertyValue(theme?.firstPosition?.at(0))} ${getPropertyValue(theme?.firstPosition?.at(1))}`}</Text>
                }

                {theme?.secondPosition &&
                  <Text $responsive={isMobile} size={isMobile ? 2 : 17} $bold color={theme!.cardMiddle.text}>{getPropertyValue(theme?.secondPosition)}</Text>
                }
              </View>

              {theme?.thirdPosition &&
                <Text maxWidth="47%" $responsive={isMobile} size={isMobile ? 2 : 17} $bold color={theme!.cardMiddle.text}>{getPropertyValue(theme?.thirdPosition)}</Text>
              }
            </View>
          </CardMiddle>
          <CardBottom
            bgColor={theme!.cardBottom.background}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <View $transparent justifyContent="center" spaceVertical={10}>
              {theme?.fourthPosition && 
                <Stack>
                  <Text $responsive={isMobile} size={isMobile ? 1.3 : 10} color={theme!.cardBottom.text}>
                    {(getPropertyValue(theme?.fourthPosition!) ? separateBeforeUppercase(theme?.fourthPosition!) : '')}
                  </Text>
                  <Text $responsive={isMobile} size={isMobile ? 2 : 17} color={theme!.cardBottom.text} $bold>
                    {getPropertyValue(theme?.fourthPosition)}
                  </Text>
                </Stack>
              }

              {theme?.fifthPosition && 
                <Stack marginTop={2}>
                  <Text $responsive={isMobile} size={isMobile ? 1.3 : 10} color={theme!.cardBottom.text}>
                    {(getPropertyValue(theme?.fifthPosition!) ? separateBeforeUppercase(theme?.fifthPosition!) : '')}
                  </Text>
                  <Text $responsive={isMobile} size={isMobile ? 2 : 17} color={theme!.cardBottom.text} $bold>
                    {getPropertyValue(theme?.fifthPosition)}
                  </Text>
                </Stack>
              }

              {/* {data.profileEndDate || profileEndDate &&
                <View spaceTop={5} $transparent>
                  <Text $responsive={isMobile} size={isMobile ? 1.3 : 10} color={theme!.cardBottom.text}>{t("digital_id.card.valid_until")}</Text>
                  <Text $responsive={isMobile} size={isMobile ? 2 : 17} $bold color={theme!.cardBottom.text}>{format(data.profileEndDate ? new Date(data.profileEndDate) : new Date(profileEndDate), "PP")}</Text>
                </View>
              } */}

              {showSecurityAnimation && (
                <SecurityHologramWrapper>
                  <SecurityHologram
                    source={require("../assets/images/security-hologram.gif")}
                  />
                </SecurityHologramWrapper>
              )}
            </View>
            {showBarcode && (
              <QRCodeWrapper>
                {data?.personStatus === PROFILE_STATUS.ACTIVATED &&
                  !!barcodeSource ? (
                  <QRCodeWithZoom
                    type={barcodeType}
                    source={barcodeSource}
                  />
                ) : (
                  <Icon
                    as={MaterialCommunityIcons}
                    name="alert-circle-outline"
                    size={50}
                    color="black"
                  />
                )}
              </QRCodeWrapper>
            )}
          </CardBottom>
        </Card>
      )}
    </View>
  );
}

export default DigitalIdComponent;
