import { Icon, ScrollView } from "native-base";
import * as React from "react";
import { Button, Text, View, Divider } from "../components/Styled";
import { useStartupConfig, useStartupUser } from "../contexts/startup/hooks";
import { useTranslation } from "../contexts/translations";
import * as Cache from "../services/cache";
import { ActivityIndicator, SafeAreaView} from "react-native";
import ClientBrand from "../components/ClientBrand";
import useIsMobile from "../hooks/useIsMobile";
import * as API from "../services/api";
import { useAuth } from "../contexts/auth/hooks";
import { FontAwesome } from "@expo/vector-icons";
import WizardHeader from "../components/WizardHeader";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { FIRST_LOGIN } from "../contexts/startup/constants/cache";

const CACHE_KEY_STARTUP_MESSAGE = "CACHE_KEY_STARTUP_MESSAGE";

function StartupMessageScreen({ children }: { children: JSX.Element }) {
  const isMobile = useIsMobile();
  const { showFixedMessage, messageToDisplay } = useStartupConfig();
  const { signOut } = useAuth();
  const { t, setLocale, locale } = useTranslation();
  const [hasAccepted, setHasAccepted] = React.useState<boolean | null>(
    showFixedMessage ? null : false
  );
  const user = useStartupUser();
  const [hasStandAccess, setHasStandAccess] = React.useState<boolean>(null);
  const [isLoadingDetails, setIsLoadingDetails] = React.useState<boolean>(true);
  const [firstLogin, setFirstLogin] = React.useState<boolean>(true);
  const [showPage, setShowPage] = React.useState<boolean | null>();

  function getLanguageCode(language: string) {
    switch (language) {
      case 'French':
        return 'fr';
      case 'English':
        return 'en';
      case 'Dutch':
        return 'nl';        
      case 'German':
        return 'de';        
      case 'Italian':
        return 'it';
      case 'Portuguese':
        return 'pt';      
      case 'Spanish':
        return 'es';              
      default:
        return 'en'; 
    }
  }

  React.useEffect(() => {
    (async () => {
      console.log(user, "user")
      const isFirstLogIn = await Cache.get(FIRST_LOGIN);
      if (isFirstLogIn != undefined && isFirstLogIn != null) {
        setFirstLogin(isFirstLogIn);
      }

      if (firstLogin === true) {
        await Cache.set(FIRST_LOGIN, "false")
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const wSettings = await API.getWizardSettings();
        if(typeof wSettings.data === "string") {
          const parsedSettings = JSON.parse(wSettings.data);
            return setShowPage(parsedSettings.data.startupPage.show)
        }
        if (wSettings.data && wSettings.data.startupPage) {
          setShowPage(wSettings.data.startupPage.show)
        }
      } catch (error) {
        setShowPage(true)
      }

      const hasAcceptedCache = await Cache.get(CACHE_KEY_STARTUP_MESSAGE);
      setHasAccepted(!!hasAcceptedCache);
  
      const cardHolderInfo = await API.getCardHolderInfo();
      let languageFromDB = cardHolderInfo?.data?.language;
      let languageCode = getLanguageCode(languageFromDB || locale);
      if(languageFromDB !== null || undefined || "") setLocale(languageCode);
    })();

  }, []);

  React.useEffect(() => {
    (async () => {
      if (user) {
        try {
          const res = await API.getUserProfiles(user?.personId);
          const userprofile = res.data?.filter((x: { profile: { selfServiceAccess: any; name: string | undefined; }; }) => x.profile.selfServiceAccess && x.profile.name === user.profileName)[0]
          if (userprofile !== undefined) {
            setHasStandAccess(true);
          }else{
            setHasStandAccess(false);
          }
          setIsLoadingDetails(false)
        } catch (error) {
          console.log("error", error);
          setIsLoadingDetails(false)
          setHasStandAccess(false);
        }
      } else {
        setIsLoadingDetails(false)
      }

    })();
  }, [user]);

  const handleNext = async () => {
    await Cache.set(CACHE_KEY_STARTUP_MESSAGE, "true");
    setHasAccepted(true);
  }

  const SubHeadView = () => {
    return (
      <ScrollView contentContainerStyle={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        <View $transparent
          spaceVertical={hp("9%")}
          flexGrow={1}
          width={!isMobile ? wp("23%")  : wp("80%")}
          justifyContent={!isMobile ? "center" : undefined}
          alignSelf="center"
        >
          {
            hasStandAccess === null ? <></> :
            !user?.archive && hasStandAccess ?
              <Text $responsive size={2} $textAlign="center">
                {
                  showFixedMessage && messageToDisplay
                    ? messageToDisplay :
                    t("startup_message.message")
                }
              </Text> :
              <Text $responsive size={2} $textAlign="center">{t("startup_message.no_access")}</Text>
          }
        </View>
      </ScrollView>
    )
  }

  const BottomButtonView = () => {
    if(hasStandAccess === null){
      return null
    }else if (!user?.archive && hasStandAccess) {
      return (
        <>
          <Divider $fullSize />
          <View spaceVertical={hp("0.5%")} $transparent flexDirection="row" width={"100%"}>
            <Button
              variant="unstyled"
              $fullWidth
              justifyContent="center"
              alignItems="center"
              disabled
              height={hp("5%")}
            >
              <Text $responsive size={1.7} color="grey">{t("auth.btn_prev")}</Text>
            </Button>
            <Divider $vertical />
            <Button
              variant="unstyled"
              $fullWidth
              justifyContent="center"
              alignItems="center"
              height={hp("5%")}
              onPress={handleNext}
            >
              <Text $responsive size={1.7} color="#2969cf" $textAlign="left">{t("auth.btn_next")}</Text>
            </Button>
          </View>
        </>
      )
    } else {
      return <Button
        variant="ghost"
        rightIcon={<Icon name="sign-out" as={FontAwesome} />}
        onPress={signOut}
        accessible
        accessibilityLabel={t("accessibility.signOut")}
        accessibilityRole="button"
      >
        {t("settings.sign_out")}
      </Button>;
    }
  }

  return ((hasAccepted === null && showPage === null ? null : 
  showPage === false || showPage === true && hasAccepted) || (!firstLogin && ((messageToDisplay != null) && showFixedMessage)) || user?.photo && user.photoStatus != "Rejected") ? (
    children
  ) : (
    <View $fullscreen>
      {
        isLoadingDetails ?
          <View $transparent flexGrow={1} justifyContent="center">
            <ActivityIndicator
              accessible
              accessibilityLabel={t("accessibility.loading", {
                text: "",
              })}
            />
          </View> :
          isMobile ?
            <SafeAreaView style={{ alignItems: "center",  flex: 1 }}>
              <ClientBrand />
              <SubHeadView />
              <BottomButtonView />
            </SafeAreaView> :
            <View $fullscreen bgColor="#eee">
              <WizardHeader />
              <View bgColor="white"
                spaceVertical={hp("5%")}
                justifyContent="center"
                borderWidth={1}
                borderColor="lightgrey"
                width={wp("50%")}
                alignSelf="center"
                style={{ flex: 1 }}
              >
                <SubHeadView />
                <View $transparent alignItems="center">
                  <BottomButtonView />
                </View>
              </View>
            </View>
      }
    </View>
  );
}

export default StartupMessageScreen;
