import * as React from "react";
import { ActivityIndicator, SafeAreaView } from "react-native";
import { Button, Toast } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { CameraCapturedPicture } from "expo-camera";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import Camera from "../components/Camera";
import ImagePicker from "../components/ImagePicker";
import { Icon, View , Text} from "../components/Styled";
import { useTranslation } from "../contexts/translations";
import Signature from "../components/Signature";
import * as API from "../services/api";
import { useAuth } from "../contexts/auth/hooks";
import useFocusTrap from "../hooks/useFocusTrap";
import useKeyboardEvent from "../hooks/useKeyboardEvent";
import {MainStackParams} from "../navigation/types"
import {
  useStartupState,
  useStartupUser,
  useStartupUserActions,
} from "../contexts/startup/hooks";

const DRAW = "draw";
const CAMERA = "camera";
const GALLERY = "gallery";
const TYPE = "type";

export default function SignatureUploadScreen() {
  const { refreshAuth } = useAuth();
  const { t } = useTranslation();
  const [active, setActive] = React.useState(DRAW);
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const [isLoading, setIsLoading] = React.useState(false);

  const { reload: reloadStartup } = useStartupUserActions();
  
  async function updateSignature(file: string) {
    setIsLoading(true);
    try {
      await refreshAuth();
      await API.uploadSignature({ file });
      Toast.show({
        accessible: true,
        accessibilityLabel: t("signature_upload.success"),
        duration: 8000,
        render:() => <View bgColor="green" style={{padding:10}}>
          <Text color="white">{t("signature_upload.success")}</Text>
        </View>
      })
    } catch (error) {
      Toast.show({
        accessible: true,
        accessibilityLabel: t("signature_upload.failed"),
        duration: 8000,
        render:() => <View bgColor="red" style={{padding:10}}>
          <Text color="white">{t("signature_upload.failed")}</Text>
        </View>
      })
      console.warn("Failed updating signature", error?.response?.data || error);
    }
    await reloadStartup();
    navigation.navigate("Profile");
  }
  function handleDraw(base64: string) {
    if (base64) {
      updateSignature(base64);
    }
  }
  function handleSelect(file: CameraCapturedPicture | ImageInfo) {
    const base64 = file.base64 || file.uri;
    if (base64) {
      updateSignature(base64);
    }
  }

  const [focusRef, tabKey, tabHandler] = useFocusTrap<MainStackParams>(navigation);

  useKeyboardEvent({
    Escape: navigation.goBack,
    [tabKey]: tabHandler,
  });

  return (
    <View $fullscreen ref={focusRef}>
      <SafeAreaView>
        <View $transparent flexDirection="row" justifyContent="space-between">
          <Button variant="ghost" onPress={navigation.goBack}
            accessible
            accessibilityLabel={t("accessibility.back_btn")}
            accessibilityRole="button"
          >
            <Icon as={MaterialCommunityIcons} name="arrow-left" />
          </Button>

          <Button.Group>
            <Button
              colorScheme="primary"
              variant={active !== DRAW ? "bordered" : undefined}
              onPress={() => setActive(DRAW)}
            >
              {t("signature_upload.draw")}
            </Button>
            <Button
              colorScheme="primary"
              variant={active !== CAMERA ? "bordered" : undefined}
              onPress={() => setActive(CAMERA)}
            >
              {t("signature_upload.camera")}
            </Button>
            <Button
              colorScheme="primary"
              variant={active !== GALLERY ? "bordered" : undefined}
              onPress={() => setActive(GALLERY)}
            >
              {t("signature_upload.gallery")}
            </Button>
            {/* <Button
              colorScheme="primary"
              variant={active !== TYPE ? "bordered" : undefined}
              onPress={() => setActive(TYPE)}
            >
              {t("signature_upload.type")}
            </Button> */}
            
          </Button.Group>
          <View $transparent width={60} />
        </View>
      </SafeAreaView>

      {active === DRAW && (
        <Signature
          text={t("signature_upload.label")}
          onSelect={handleDraw}
          clearText={t("signature_upload.clear")}
          confirmText={t("signature_upload.confirm")}
        />
      )}
      {active === CAMERA && (
        <Camera onCancel={navigation.goBack} onSelect={handleSelect} />
      )}
      {active === GALLERY && (
        <ImagePicker onSelect={handleSelect}>
          {isLoading && (
            <ActivityIndicator
              accessible
              accessibilityLabel={t("accessibility.loading", {
                text: "",
              })}
            />
          )}
        </ImagePicker>
      )}
    </View>
  );
}
