import * as React from "react";
import * as Cache from "../services/cache";
import { Toast } from "native-base";
import {
  StyleSheet,
  Platform,
  ActivityIndicator,
  SafeAreaView,
  Alert,
} from "react-native";
import { Pressable, ScrollView } from "native-base";
import { CameraCapturedPicture } from "expo-camera";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import Camera from "../components/Camera";
import { Icon, View, Text, Divider, Button, Image } from "../components/Styled";
import { useTranslation } from "../contexts/translations";
import {
  useStartupConfig,
  useStartupUser,
  useStartupState,
} from "../contexts/startup/hooks";
import {
  uploadIdentity,
  getWizardSettings,
  getAllThemes,
} from "../services/api";
import useKeyboardEvent from "../hooks/useKeyboardEvent";
import { EvilIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import ClientBrand from "../components/ClientBrand";
import { manipulateAsync, FlipType, SaveFormat } from "expo-image-manipulator";
import ImagePicker from "../components/ImagePicker";
import useIsMobile from "../hooks/useIsMobile";
import WizardHeader from "../components/WizardHeader";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useAuth } from "../contexts/auth/hooks";
import { APPROVED } from "../constants/photo-status";
import { useTheme } from "../contexts/theme/hooks";
import { DARK_MODE } from "../constants/Colors";
import { useAlert } from "../hooks/useAlert";

const CAMERA = "camera";
const GALLERY = "gallery";
const SHOW_MODAL = "SHOW_MODAL";
const SHOW_GALLERY = "SHOW_GALLERY";
const SHOW_APP = "SHOW_APP";
const SHOW_THANK_YOU = "SHOW_THANK_YOU";

function stateReducer({
  requestIdentityOnStartup,
  allowWithApprovedPhoto,
  identityExists,
  photoApproved,
  photoStatus,
}: {
  requestIdentityOnStartup?: boolean;
  allowWithApprovedPhoto?: boolean;
  identityExists?: boolean;
  photoApproved?: boolean;
  photoStatus?: string;
}) {
  const hasApprovedPhoto = photoStatus === APPROVED || photoApproved;

  if (requestIdentityOnStartup && !identityExists) {
    if (allowWithApprovedPhoto && hasApprovedPhoto) {
      return SHOW_APP;
    }
    return SHOW_MODAL;
  }
}

const Modal = ({
  onSelect,
}: {
  onSelect: (
    options: typeof SHOW_MODAL | typeof SHOW_GALLERY | typeof SHOW_APP
  ) => void;
}) => {
  const { t } = useTranslation();
  const { acceptedIdentityTypes, identityIsMandatory, sampleDocumentId } =
    useStartupConfig();
  const isMobile = useIsMobile();
  const { signOut } = useAuth();
  const [theme] = useTheme();

  const SubHeadView = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View
          $transparent
          spaceVertical={hp("9%")}
          flexGrow={1}
          width={!isMobile ? wp("23%") : wp("80%")}
          alignSelf="center"
        >
          <Text $responsive size={1.8} $textAlign="center">
            {identityIsMandatory
              ? t("identity_upload.label_mandatory")
              : t("identity_upload.label_not_mandatory")}
          </Text>

          <View $transparent spaceVertical={hp("1%")} />

          <Text $responsive size={1.5} $textAlign="center">
            {`${t(
              "identity_upload.documents_accepted"
            )} ${acceptedIdentityTypes}`}{" "}
          </Text>

          {sampleDocumentId && (
            <View
              $transparent
              height={hp("25%")}
              width={wp("90%")}
              spaceTop={hp("3%")}
              alignSelf="center"
            >
              <Image
                style={{
                  flex: 1,
                  ...StyleSheet.absoluteFillObject,
                  resizeMode: "contain",
                }}
                source={{ uri: `data:image/png;base64,${sampleDocumentId}` }}
              />
            </View>
          )}
        </View>
      </ScrollView>
    );
  };

  const alert = useAlert(
    t("alert.warning"),
    t("identity_upload.identity_required"),
    t("alert.cancel"),
    t("alert.confirm")
  );

  const BottomButtonView = () => {
    const handleShowGallery = () => {
      onSelect(SHOW_GALLERY);
    };
    return (
      <>
        <Divider $fullSize color={theme === DARK_MODE ? "#5c5b5b" : "#ddd"} />

        <View
          spaceVertical={hp("0.5%")}
          $transparent
          flexDirection="row"
          width={"100%"}
        >
          <Button
            variant="unstyled"
            alignItems="center"
            justifyContent="center"
            $fullWidth
            height={hp("5%")}
            onPress={() => {
              if (identityIsMandatory) {
                Alert.alert(
                  t("alert.warning"),
                  t("identity_upload.identity_required"),
                  [
                    {
                      text: t("alert.confirm"),
                      style: "confirm",
                      onPress: () => signOut(),
                    },
                  ]
                );
              } else {
                onSelect(SHOW_APP);
              }
            }}
          >
            <Text color="#2969cf" $responsive size={1.7}>
              {t("auth.no_thanks")}
            </Text>
          </Button>
          <Divider $vertical />
          <Button
            variant="unstyled"
            alignItems="center"
            justifyContent="center"
            $fullWidth
            height={hp("5%")}
            onPress={handleShowGallery}
          >
            <Text color="#2969cf" $responsive size={1.7}>
              {t("auth.btn_next")}
            </Text>
          </Button>
        </View>
      </>
    );
  };

  return isMobile ? (
    <View $fullscreen>
      <SafeAreaView style={{ alignItems: "center", flex: 1 }}>
        <ClientBrand />
        <Text accessibilityRole="header" $bold $responsive size={3}>
          {t("header.id_document")}
        </Text>

        <SubHeadView />

        <BottomButtonView />
      </SafeAreaView>
    </View>
  ) : (
    <View $fullscreen bgColor={theme === DARK_MODE ? "#313131" : "#F7F7F7"}>
      <WizardHeader />
      <View
        bgColor={theme === DARK_MODE ? "black" : "white"}
        spaceVertical={hp("5%")}
        justifyContent="center"
        borderWidth={1}
        borderColor={theme === DARK_MODE ? "#5c5b5b" : "#ddd"}
        width={wp("50%")}
        alignSelf="center"
        style={{ flex: 1 }}
      >
        <View $transparent spaceTop={hp("10%")}>
          <Text
            accessibilityRole="header"
            $bold
            $textAlign="center"
            $responsive
            size={3}
          >
            {t("header.id_document")}
          </Text>
        </View>
        <SubHeadView />
        <View $transparent alignItems="center">
          <BottomButtonView />
        </View>
      </View>
    </View>
  );
};

type ImageData = {
  fullUri: boolean;
  mime: string;
  uri: string;
  base64: string;
  height: any;
  width: any;
  size: any;
};

const Gallery = ({
  onClose,
  onComplete,
}: {
  onClose: () => void;
  onComplete: () => void;
}) => {
  const { t } = useTranslation();
  const [active, setActive] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [picture, setPicture] = React.useState<ImageData>();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const isMobile = useIsMobile();
  const [errmessage, setErrMessage] = React.useState("");
  const { photoMessageToDisplay } = useStartupConfig();
  const [theme] = useTheme();

  useKeyboardEvent({
    Escape: onClose,
  });

  async function upload(file: ImageData | CameraCapturedPicture | ImageInfo) {
    var image = file.base64 || file.uri;
    if (!image) {
      return;
    }

    const fileSize = image.length * (3 / 4) - 2;
    if (fileSize > 6000000) {
      Image.getSize(file.uri, async (width, height) => {
        const croppedImage = await manipulateAsync(
          file.uri,
          [
            {
              resize: {
                width: width - width * 0.5,
                height: height - height * 0.5,
              },
            },
          ],
          {
            compress: 0.7,
          }
        );

        image = croppedImage.base64 || croppedImage.uri;
      });
    }

    try {
      setIsLoading(true);
      const res = await uploadIdentity({ file: image });
      if (res.status === 200) {
        setIsSubmitted(true);
        setIsLoading(false);
      } else {
        setErrMessage(t("identity_upload.error"));
      }
    } catch (error) {
      setIsLoading(false);
      Toast.show({
        accessible: true,
        accessibilityLabel: t("profile.photo_edit.failed"),
        duration: 8000,
        render: () => (
          <View bgColor="orange" style={{ padding: 10 }}>
            <Text color="white">{t("profile.photo_edit.failed")}</Text>
          </View>
        ),
      });
      setErrMessage(t("identity_upload.error"));
    }
  }

  if (active === CAMERA) {
    return (
      <Camera
        onCancel={() => setActive("")}
        onSelect={(image) => {
          setPicture({
            uri: image.uri,
            height: image.height,
            width: image.width,
            base64: image.base64,
            fullUri: true,
          });
          setActive("");
        }}
      />
    );
  }

  function gotoImagePicker() {
    const ImagePicker = require("react-native-image-crop-picker");
    ImagePicker.openPicker({
      includeBase64: true,
      compressImageQuality: 1,
    }).then(
      (image: {
        mime: any;
        path: any;
        height: any;
        width: any;
        size: any;
        data: any;
      }) => {
        setPicture({
          mime: image.mime,
          uri: image.path,
          height: image.height,
          width: image.width,
          size: image.size,
          base64: image?.data,
          fullUri: false,
          originalUri: image.path,
        });
      }
    );
  }

  function gotoCamera() {
    const ImagePicker = require("react-native-image-crop-picker");
    ImagePicker.openCamera({
      includeBase64: true,
      compressImageQuality: 1,
    }).then(
      (image: {
        mime: any;
        path: any;
        height: any;
        width: any;
        size: any;
        data: any;
      }) => {
        setPicture({
          mime: image.mime,
          uri: image.path,
          height: image.height,
          width: image.width,
          size: image.size,
          base64: image?.data,
          fullUri: false,
          originalUri: image.path,
        });
      }
    );
  }

  function cropImage() {
    const ImagePicker = require("react-native-image-crop-picker");
    ImagePicker.openCropper({
      path: picture?.originalUri,
      includeBase64: true,
      freeStyleCropEnabled: false,
      cropping: true,
      compressImageQuality: 1,
      width: 500,
      height: 300,
    }).then(
      (image: {
        mime: any;
        path: any;
        height: any;
        width: any;
        size: any;
        data: any;
      }) => {
        setPicture({
          mime: image.mime,
          uri: image.path,
          height: image.height,
          width: image.width,
          size: image.size,
          base64: image?.data,
          fullUri: false,
          originalUri: picture?.originalUri,
        });
      }
    );
  }

  const GalleryBody = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View
          $transparent
          spaceVertical={hp("9%")}
          flexGrow={1}
          width={!isMobile ? wp("23%") : wp("80%")}
          alignSelf="center"
        >
          <Pressable
            height={hp("25%")}
            width={!isMobile ? wp("23%") : wp("90%")}
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            backgroundColor={"#eee"}
            disabled={!picture}
            onPress={() => {
              if (Platform.OS !== "web") {
                cropImage();
              }
            }}
          >
            {picture ? (
              <Image
                style={{
                  flex: 1,
                  ...StyleSheet.absoluteFillObject,
                  resizeMode: "contain",
                }}
                source={{
                  uri: picture.fullUri
                    ? picture.uri
                    : `data:${picture.mime};base64,${picture.base64}`,
                }}
              />
            ) : (
              <Icon
                as={EvilIcons}
                name="image"
                size={hp("20%")}
                color="lightgrey"
              />
            )}
          </Pressable>

          <View
            $transparent
            alignSelf="center"
            width={!isMobile ? wp("43%") : wp("100%")}
          >
            {!isSubmitted && Platform.OS !== "web" && picture && (
              <View $transparent spaceTop={hp("2%")}>
                <Text $textAlign="center" $responsive size={1.5}>
                  {t("message_label.tap_and_crop")}
                </Text>
              </View>
            )}
            {isLoading ? (
              <View $transparent spaceVertical={hp("8%")}>
                <Text $responsive size={1.5} $textAlign="center">
                  {t("identity_upload.loading_message")}
                </Text>
              </View>
            ) : isSubmitted || errmessage !== "" ? (
              <View $transparent spaceVertical={hp("3%")}>
                {errmessage !== "" ? (
                  <View>
                    <View
                      height={hp("5%")}
                      spaceVertical={hp("1%")}
                      $transparent
                      flexDirection="row"
                      spaceHorizontal={wp("10%")}
                    >
                      <Button
                        style={{ marginRight: wp("0.2%"), flex: 1 }}
                        colorScheme={
                          errmessage.length
                            ? "gray"
                            : picture
                            ? "success"
                            : "primary"
                        }
                        disabled={!!errmessage.length}
                        // height={hp("5%")}
                        onPress={() => {
                          if (picture) {
                            upload(picture);
                          } else {
                            gotoImagePicker();
                          }
                        }}
                      >
                        <Text color="white" $responsive size={1.5}>
                          {picture
                            ? t("photo.btn_submit")
                            : t("photo.btn_upload")}
                        </Text>
                      </Button>
                      <Button
                        style={{ marginLeft: wp("0.2%"), flex: 1 }}
                        colorScheme={picture ? "error" : "primary"}
                        // height={hp("5%")}
                        onPress={() => {
                          setErrMessage("");
                          if (picture) {
                            setPicture(undefined);
                          } else {
                            if (Platform.OS === "web") {
                              setActive(CAMERA);
                            } else {
                              gotoCamera();
                            }
                          }
                        }}
                      >
                        <Text color="white" $responsive size={1.5}>
                          {picture
                            ? t("photo.btn_retake")
                            : t("photo.btn_capture")}
                        </Text>
                      </Button>
                    </View>
                    <View>
                      <Text
                        $textAlign="center"
                        $bold
                        $responsive
                        size={1.7}
                        color="red"
                      >
                        <MaterialCommunityIcons name="close" size={hp("3%")} />
                        {t("identity_upload.error_msg")}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <Text
                    $textAlign="center"
                    $bold
                    $responsive
                    size={1.7}
                    color="green"
                  >
                    <MaterialCommunityIcons name="check" size={hp("3%")} />
                    {t("identity_upload.submitted")}
                  </Text>
                )}

                <View spaceTop={10} $transparent spaceHorizontal={50}>
                  {errmessage !== "" ? (
                    <Text $textAlign="center" $responsive size={1.5}>
                      {errmessage}
                    </Text>
                  ) : (
                    <Text $textAlign="center" $responsive size={1.5}>
                      {t("message_label.success_msg_id")}
                    </Text>
                  )}
                </View>
              </View>
            ) : (
              <View
                height={hp("5%")}
                spaceVertical={hp("1%")}
                $transparent
                flexDirection="row"
                spaceHorizontal={wp("10%")}
              >
                {!picture && Platform.OS === "web" ? (
                  <ImagePicker
                    onCancel={onClose}
                    onSelect={(image) => {
                      setPicture({
                        uri: image.uri,
                        height: image.height,
                        width: image.width,
                        base64: image.base64,
                      });
                    }}
                  />
                ) : (
                  <Button
                    style={{ marginRight: wp("0.2%"), flex: 1 }}
                    colorScheme={picture ? "success" : "primary"}
                    // height={hp("5%")}
                    onPress={() => {
                      if (picture) {
                        upload(picture);
                      } else {
                        gotoImagePicker();
                      }
                    }}
                  >
                    <Text color="white" $responsive size={1.5}>
                      {picture ? t("photo.btn_submit") : t("photo.btn_upload")}
                    </Text>
                  </Button>
                )}
                <Button
                  style={{ marginLeft: wp("0.2%"), flex: 1 }}
                  // colorScheme={picture ? "error" : "primary"}
                  colorScheme={
                    !picture && Platform.OS === "web" ? "error" : "primary"
                  }
                  // height={hp("5%")}
                  // height={hp("5%")}
                  onPress={() => {
                    if (picture) {
                      setPicture(undefined);
                    } else {
                      if (Platform.OS === "web") {
                        setActive(CAMERA);
                      } else {
                        gotoCamera();
                      }
                    }
                  }}
                >
                  <Text color="white" $responsive size={1.5}>
                    {picture ? t("photo.btn_retake") : t("photo.btn_capture")}
                  </Text>
                </Button>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    );
  };

  const GalleryBtnBottom = () => {
    return (
      <>
        <Divider $fullSize color={theme === DARK_MODE ? "#5c5b5b" : "#ddd"} />

        <View
          spaceVertical={hp("0.5%")}
          $transparent
          flexDirection="row"
          width={"100%"}
        >
          <Button
            variant="unstyled"
            alignItems="center"
            justifyContent="center"
            $fullWidth
            height={hp("5%")}
            onPress={() => {
              setErrMessage("");
              setPicture(undefined);
              onClose();
            }}
          >
            <Text color={"#2969cf"} $responsive size={1.7}>
              {t("auth.btn_prev")}
            </Text>
          </Button>
          <Divider $vertical />
          <Button
            variant="unstyled"
            alignItems="center"
            justifyContent="center"
            $fullWidth
            height={hp("5%")}
            disabled={!picture || !isSubmitted}
            onPress={() => onComplete()}
          >
            <Text
              color={picture && isSubmitted ? "#2969cf" : "grey"}
              $responsive
              size={1.7}
            >
              {t("auth.btn_next")}
            </Text>
          </Button>
        </View>
      </>
    );
  };

  return isMobile ? (
    <View $fullscreen>
      <SafeAreaView style={{ alignItems: "center", flex: 1 }}>
        <ClientBrand />
        <Text
          accessibilityRole="header"
          $bold
          $textAlign="center"
          $responsive
          size={3}
        >
          {t("header.id_document")}
        </Text>

        <GalleryBody />
        <GalleryBtnBottom />
      </SafeAreaView>
    </View>
  ) : (
    <View $fullscreen bgColor={theme === DARK_MODE ? "#313131" : "#F7F7F7"}>
      <WizardHeader />
      <View
        bgColor={theme === DARK_MODE ? "black" : "white"}
        spaceVertical={hp("5%")}
        justifyContent="center"
        borderWidth={1}
        borderColor={theme === DARK_MODE ? "#5c5b5b" : "#ddd"}
        width={wp("50%")}
        alignSelf="center"
        style={{ flex: 1 }}
      >
        <View $transparent spaceTop={90}>
          <Text
            accessibilityRole="header"
            $bold
            $textAlign="center"
            $responsive
            size={3}
          >
            {t("header.id_document")}
          </Text>
        </View>
        <GalleryBody />
        <GalleryBtnBottom />
      </View>
    </View>
  );
};

export default function IdentityUploadScreen({
  children,
}: {
  children: JSX.Element;
}) {
  const { isLoading } = useStartupState();
  const { t } = useTranslation();
  const { requestIdentityOnStartup, allowWithApprovedPhoto } =
    useStartupConfig();
  const { identityExists, photoApproved, photoStatus } = useStartupUser() || {};
  const derivedState = stateReducer({
    requestIdentityOnStartup,
    allowWithApprovedPhoto,
    identityExists,
    photoApproved,
    photoStatus,
  });
  const [state, setState] = React.useState(derivedState);

  React.useEffect(() => {
    if (requestIdentityOnStartup) {
      if ((allowWithApprovedPhoto && photoApproved) || identityExists) {
        setState(SHOW_APP);
      } else {
        setState(SHOW_MODAL);
      }
    } else {
      setState(SHOW_APP);
    }
  }, []);

  if (!isLoading) {
    if (state === SHOW_APP) {
      return children;
    }
    if (state === SHOW_MODAL) {
      return <Modal onSelect={setState} />;
    }

    return (
      <Gallery
        onClose={async () => {
          setState(SHOW_MODAL);
        }}
        onComplete={async () => {
          await Cache.set("subId", "true");
          setState(SHOW_APP);
        }}
      />
    );
  }

  return (
    <View $transparent flexGrow={1} justifyContent="center">
      <ActivityIndicator
        accessible
        accessibilityLabel={t("accessibility.loading", {
          text: "",
        })}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  logo: {
    width: 100,
    height: 100,
    marginBottom: 20,
    alignSelf: "center",
  },
  headingText: {
    fontWeight: "bold",
    textAlign: "center",
  },
  pressableBtn: {
    flex: Platform.OS === "android" || Platform.OS === "ios" ? 1 : undefined,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
  },
});
