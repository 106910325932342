import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HStack, Pressable, VStack } from "native-base";
import * as React from "react";
import * as Cache from "../services/cache";
import {
  StyleSheet,
  NativeModules,
  Platform,
  ActivityIndicator,
  DeviceEventEmitter,
  Alert,
  NativeEventEmitter,
} from "react-native";
import { View, Divider, Text, Icon, Image, Button } from "../components/Styled";
import { useTranslation } from "../contexts/translations";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MainStackParams } from "../navigation/types";
import styled from "styled-components/native";
import { CardHolderContext } from "../contexts/card-holder";
import { useTheme } from "../contexts/theme/hooks";
import { DARK_MODE } from "../constants/Colors";
import { useStartupUser, useStartupConfig } from "../contexts/startup/hooks";
import { ScrollView } from "react-native-gesture-handler";
import * as API from "../services/api";
import {
  ACTIVATED,
  BLOCKED,
  INITIALIZED,
  IN_PRODUCTION,
  PRINTED,
  WITHDRAWN,
  APPROVED,
  EXPIRED,
} from "../constants/card-status";
import { ICard } from "../interfaces/card.interface";

const ThemedIcon = styled(Icon)`
  color: ${(props) => props.theme.textColor};
`;

interface userCard {
  userCard: string;
  name: string;
}

export default function CredentialScreen() {
  const { t } = useTranslation();
  const user = useStartupUser();
  const { allowUserManageCredentials } = useStartupConfig();
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const [{ data }] = React.useContext(CardHolderContext);
  const [cardsPerType, setCardsPerType] = React.useState<ICard[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [theme] = useTheme();

  function gotoCredentialAction(val: any) {
    const params: userCard = {
      userCard: val,
      name: val.type.name,
    };
    navigation.navigate("CredentialAction", params);
  }

  const cardholderID = !data?.cardholderId
    ? user?.cardholderId
    : data?.cardholderId;

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const resultData = await API.getCardCredentials(cardholderID);
        if (resultData.data && resultData.data[0]) {
          const cardstype = Array.from(resultData.data).find(
            (s: any) => s.cardholder.id == user?.cardholderId
          )?.cardsPerType;

          var newCards: ICard[] = [];

          cardstype.map((value: any) => {
            value.cards.map((card: ICard) => {
              if(card.blockReason === null) {
                newCards.push(card)
              }
            })
          }
          );

          setCardsPerType(newCards);
          
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <View
      $transparent
      height={300}
      as={ScrollView}
      contentContainerStyle={{ flex: 1 }}
    >
      <ScrollView>
        <Divider $fullSize spaceVertical={1} />
        {cardsPerType.map((val, index) => (
          <View key={index} $transparent>
            <Pressable
              disabled={!allowUserManageCredentials}
              onPress={() => gotoCredentialAction(val)}
            >
              <HStack
                style={styles.pressableMennu}
                justifyContent="space-between"
              >
                <VStack space={0}>
                  <Text size={15} style={{ paddingTop: 5 }}>
                    {val?.type?.name}
                  </Text>
                  <View $transparent flexDirection="row" spaceVertical={5}>
                    <Text
                      size={13}
                      style={{ paddingVertical: 5, paddingRight: 15 }}
                      $bold
                      color={
                        val?.status === BLOCKED
                          ? "red"
                          : val?.status === ACTIVATED
                          ? "green"
                          : "orange"
                      }
                    >
                      {val === null
                        ? t("credential_status.request").toUpperCase()
                        : t(`credential_status.${val?.status}`).toUpperCase()}
                    </Text>
                    {val?.type?.category?.digitalCredentialSystem ===
                      "hid_mobile_access" && (
                      <View $transparent width={50} height={25}>
                        <Image
                          style={{
                            flex: 1,
                            ...StyleSheet.absoluteFillObject,
                            resizeMode: "contain",
                            width: 50,
                            height: 25,
                          }}
                          source={
                            theme === DARK_MODE
                              ? require("../assets/images/seos-logo/SEOS-by-ASSA-ABLOY-RGB-White.png")
                              : require("../assets/images/seos-logo/SEOS-by-ASSA-ABLOY-RGB-Black.png")
                          }
                        />
                      </View>
                    )}
                  </View>
                </VStack>
                <HStack space={5} style={{ alignItems: "center" }}>
                  {val?.type?.category?.digitalCredentialSystem ===
                    "salto_space" &&
                    val?.type?.category?.isDigital &&
                    val?.status === ACTIVATED && (
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                        $transparent
                        width={40}
                        height={50}
                      >
                        
                      </View>
                    )}
                  {allowUserManageCredentials && (
                    <ThemedIcon
                      size={40}
                      as={MaterialCommunityIcons}
                      name="chevron-right"
                    />
                  )}
                </HStack>
              </HStack>
            </Pressable>

            <Divider $fullSize spaceVertical={1} />
          </View>
        ))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pressableMennu: {
    paddingVertical: 10,
  },
  loadContainer: {
    flex: 1,
    justifyContent: "center",
  },
});
