import * as React from "react";
import { AxiosError } from "axios";
import { CameraCapturedPicture } from "expo-camera";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import { Platform } from "react-native";
import { Toast } from "native-base";
import * as Api from "../services/api";
import * as Cache from "../services/cache";
import { CardStatus } from "../constants/card-status";
import { useTranslation } from "./translations";
import { useAuth } from "./auth/hooks";
import { safeMergeValues } from "../utils";
import { useStartupConfig, useStartupUserActions } from "./startup/hooks";
import EXIF from "exif-js";
import * as ImageManipulator from 'expo-image-manipulator';
import { View, Text } from "../components/Styled";

const CACHE_USER_DATA = "user";

export type UserProfileData = {
  id: string;
  personId: string;
  personNumber: string;
  cardId: string;
  cardNumber: number;
  cardStatus: CardStatus;
  cardEndDate: Date;
  firstName: string;
  lastName: string;
  photo: string;
  profileName: string;
  language: string;
  addresses: StandAddress[];
  [key: string]: any;
};

export type CardHolderState = {
  initialized: boolean;
  isLoading: boolean;
  data: UserProfileData | null;
  error?: AxiosError | string;
};

const ON_REQUEST = "ON_REQUEST";
const ON_CACHE_LOADED = "ON_CACHE_LOADED";
const ON_RESPONSE = "ON_RESPONSE";
const ON_UPDATE_RESPONSE = "ON_UPDATE_RESPONSE";
const ON_ERROR = "ON_ERROR";

export type CardHolderActions =
  | { type: typeof ON_REQUEST }
  | { type: typeof ON_CACHE_LOADED; data: UserProfileData | null }
  | { type: typeof ON_RESPONSE; data: UserProfileData }
  | { type: typeof ON_UPDATE_RESPONSE; data: Partial<UserProfileData> }
  | { type: typeof ON_ERROR; error: AxiosError | string };

export type CardHolderMethods = {
  updateStatus: (status?: CardStatus) => void | Promise<void>;
  updatePhoto: (
    photo: CameraCapturedPicture | ImageInfo,
    approved: boolean
  ) => void | Promise<void>;
  updateData: (data: Partial<UserProfileData>) => void | Promise<void>;
  updateCardHolderData: (cardholderId: string, data: any) => void | Promise<void>;
  reload: () => void | Promise<void>;
  requestSwitchCardHolderInfo: (data: Partial<UserProfileData>) => void | Promise<void>;
};

function reducer(prevState: CardHolderState, action: CardHolderActions) {
  switch (action.type) {
    case ON_CACHE_LOADED:
      return {
        ...prevState,
        initialized: true,
        data: action.data,
      };
    case ON_REQUEST:
      return {
        ...prevState,
        isLoading: true,
      };
    case ON_UPDATE_RESPONSE:
      return {
        ...prevState,
        isLoading: false,
        data: safeMergeValues(prevState.data, action.data) as UserProfileData,
      };
    case ON_RESPONSE:
      return {
        ...prevState,
        isLoading: false,
        data: action.data,
      };
    case ON_ERROR:
      return {
        ...prevState,
        isLoading: false,
        error: action.error,
      };
    default:
      return prevState;
  }
}

const initialState = {
  initialized: false,
  isLoading: false,
  data: null,
};

export type CardHolderContextValue = [CardHolderState, CardHolderMethods];

function correctFileOrientation(photoOrientation?: number) {
  if (Platform.OS === "android") {
    return photoOrientation;
  }

  let finalRotation = photoOrientation || 0;

  if (finalRotation > 0) {
    while (finalRotation > 360) {
      finalRotation -= 360;
    }
  } else if (finalRotation < 0) {
    while (finalRotation < -360) {
      finalRotation += 360;
    }
  }

  switch (finalRotation) {
    case -90:
    case -270:
      return 6;
    case 180:
    case -180:
      return 3;
    case 90:
    case 270:
      return 8;
    default:
      return 1;
  }
}

const base64ToArrayBuffer = (base64: string) => {
  var binaryString = atob(base64);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

const getOrientationFromBase64 = (base64: string) => {
  let exif;
  try {
    let arrayBuffer = base64ToArrayBuffer(
      base64.slice(base64.indexOf(",") + 1)
    );
    exif = EXIF.readFromBinaryFile(arrayBuffer);
  } catch (error) {
    console.log("Unable to parse base64", error);
  }
  return exif?.Orientation;
};

const initialContextValue: CardHolderContextValue = [
  initialState,
  {
    updateStatus: () => undefined,
    updatePhoto: () => undefined,
    updateData: () => undefined,
    updateCardHolderData: () => undefined,
    reload: () => { },
    requestSwitchCardHolderInfo: () => undefined
  },
];

export const CardHolderContext =
  React.createContext<CardHolderContextValue>(initialContextValue);

export type ApiAddress = {
  country: string;
  created?: string;
  id: string;
  locality: string;
  metadata?: object;
  object?: string;
  person: string;
  postalCode: string;
  region: string;
  streetAddress: string;
  type: "none" | "home" | "business" | "po_bo";
};

export type StandAddress = ApiAddress & {
  formatted: string;
};

const CardHolderProvider: React.FC<{}> = ({ children }) => {
  const { t } = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { refreshAuth } = useAuth();
  const { reload } = useStartupUserActions();
  const { photoMessageToDisplay } = useStartupConfig();

  async function requestCardHolderInfo() {
    var cacheddata = await Cache.get("user")

    try {
      await refreshAuth();

      let { data } = await Api.getCardHolderInfo();
      if (data) {
        cacheddata = data;
        // if (cacheddata.data) {
        //   data = cacheddata.data;
        // } else {
        //   if (cacheddata.profileName !== data.profileName) {
        //     data = cacheddata;
        //   }
        // }
      }

      try {
        await Cache.set(CACHE_USER_DATA, JSON.stringify(cacheddata));

      } catch (seterror) {
        console.log("seterror",seterror)
      }
      dispatch({
        type: ON_UPDATE_RESPONSE,
        data: cacheddata,
      });
    } catch (error) {
      if (cacheddata) {
        dispatch({
          type: ON_UPDATE_RESPONSE,
          data: cacheddata
        });
      } else {
        console.warn("Failed requesting card holder info", error);
        dispatch({ type: ON_ERROR, error });
      }
    }
  }

  React.useEffect(() => {
    requestCardHolderInfo();
    dispatch({ type: ON_REQUEST });
  }, []);

  const requestSwitchCardHolderInfo = React.useCallback(
    async (newData: Partial<UserProfileData>) => {
      if (!state.data || !newData) return;

      dispatch({ type: ON_REQUEST });
      try {
        const { data } = await Api.getNewCardHolderInfo(newData.profile?.id);

        Toast.show({
          accessible: true,
          accessibilityLabel: t("profile.edit.success"),
          duration: 8000,
          render: () => <View bgColor="green" style={{ padding: 10 }}>
            <Text color="white">{t("profile.edit.success")}</Text>
          </View>
        })

        data["cardholderProfile"] = {
          businessUnit: newData.businessUnit,
          costAccount: newData.costAccount,
          department: newData.department,
          email: newData.email,
          externalCompany: newData.externalCompany,
          freeField1: newData.freeField1,
          freeField2: newData.freeField2,
          jobTitle: newData.jobTitle,
          location: newData.location,
          nameOnCard: newData.nameOnCard,
          note: newData.note
        };
        data["cardholderId"] = newData.id;

        try {
          await Cache.set(
            CACHE_USER_DATA,
            JSON.stringify({
              ...state.data,
              data
            })
          );
        } catch (seterror) {
          console.log("seterror",seterror)
        }
        
        dispatch({
          type: ON_UPDATE_RESPONSE,
          data: data,
        });
      } catch (error) {
        console.log("error", error)
      }
    },
    [state, dispatch]
  )

  const updatePhoto = React.useCallback(
    async (file: CameraCapturedPicture | ImageInfo, approved: boolean) => {
      dispatch({ type: ON_REQUEST });
      var upload = file?.base64 || file?.uri;

      const fileSize = upload.length * (3 / 4) - 2;
      if (fileSize > 6000000) {
        const manipResult = await ImageManipulator.manipulateAsync(
          file.uri,
          [{ resize: { width: file.width * 0.5, height: file.height * 0.5 } }],
          { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
        );
        upload = manipResult.base64 || manipResult.uri;
      }

      // const orientation =
      //   file.exif !== undefined
      //     ? correctFileOrientation(file.exif.Orientation)
      //     : getOrientationFromBase64(upload);

      try {
        await refreshAuth();
        const res = await Api.updatePhoto({
          // orientation: 1,
          photo: upload,
          approved,
        });

        const photo = res.data.content ? res.data.content : upload;

        reload();

        dispatch({
          type: ON_UPDATE_RESPONSE,
          data: { photo },
        });
      } catch (error) {
        if (error.response?.data?.errors?.faceMatch) {
          error.response.data.errors.faceMatch.forEach((title: string) => {
            Toast.show({
              accessible: true,
              accessibilityLabel: t("profile.photo_edit.failed"),
              duration: 8000,
              render: () => <View bgColor="orange" style={{ padding: 10 }}>
                <Text color="white">{title}</Text>
              </View>
            })
          });
        } else {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("profile.photo_edit.failed"),
            duration: 8000,
            render: () => <View bgColor="orange" style={{ padding: 10 }}>
              <Text color="white">{t("profile.photo_edit.failed")}</Text>
            </View>
          })
        }
        console.warn("Failed uploading photo", error?.response?.data || error);
        dispatch({ type: ON_ERROR, error });
      }
    },
    [state, dispatch]
  );

  const updateStatus = React.useCallback(
    async (status) => {
      if (!state.data || !status) return;

      dispatch({ type: ON_REQUEST });
      try {
        await refreshAuth();
        const res = await Api.udateCardStatus(state.data.cardId, {
          status,
        });
        if (res.data.status === status) {

          Toast.show({
            accessible: true,
            accessibilityLabel: t("digital_id.update_status.success"),
            duration: 8000,
            render: () => <View bgColor="green" style={{ padding: 10 }}>
              <Text color="white">{t("digital_id.update_status.success")}</Text>
            </View>
          })

          await Cache.set(
            CACHE_USER_DATA,
            JSON.stringify({
              ...state.data,
              cardStatus: res.data.status,
            })
          );

          dispatch({
            type: ON_UPDATE_RESPONSE,
            data: { cardStatus: res.data.status },
          });
        } else {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("digital_id.update_status.error"),
            duration: 8000,
            render: () => <View bgColor="orange" style={{ padding: 10 }}>
              <Text color="white">{t("digital_id.update_status.error")}</Text>
            </View>
          })
          dispatch({ type: ON_ERROR, error: "Invalid response" });
        }
      } catch (error) {
        Toast.show({
          accessible: true,
          accessibilityLabel: t("digital_id.update_status.error"),
          duration: 8000,
          render: () => <View bgColor="orange" style={{ padding: 10 }}>
            <Text color="white">{t("digital_id.update_status.error")}</Text>
          </View>
        })
        console.warn("Failed updating card status", error.response.data);
        dispatch({ type: ON_ERROR, error });
      }
    },
    [state.data]
  );

  const updateCardHolderData = React.useCallback(
    async (cardholderId: string, newData: any) => {
      if (!state.data || !newData) return;

      dispatch({ type: ON_REQUEST });
      try {
        await refreshAuth();
        const res = await Api.updateCardHolder(cardholderId, newData);
        if (res.data) {
          dispatch({
            type: ON_UPDATE_RESPONSE,
            data: res.data,
          });
        } else {
          dispatch({ type: ON_ERROR, error: "Invalid response" });
        }
      } catch (error) {
        console.log("Failed updating user data", error.response.data);
        dispatch({ type: ON_ERROR, error });
      }
    },
    [state.data]
  );

  const updateData = React.useCallback(
    async (newData: Partial<UserProfileData>) => {
      if (!state.data || !newData) return;

      dispatch({ type: ON_REQUEST });
      try {
        await refreshAuth();
        const res = await Api.updateUser(newData);
        if (res.data) {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("profile.edit.success"),
            duration: 8000,
            render: () => <View bgColor="green" style={{ padding: 10 }}>
              <Text color="white">{t("profile.edit.success")}</Text>
            </View>
          })

          await Cache.set(
            CACHE_USER_DATA,
            JSON.stringify({
              ...state.data,
              ...res.data,
            })
          );

          dispatch({
            type: ON_UPDATE_RESPONSE,
            data: res.data,
          });
        } else {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("profile.edit.failed"),
            duration: 8000,
            render: () => <View bgColor="orange" style={{ padding: 10 }}>
              <Text color="white">{t("profile.edit.failed")}</Text>
            </View>
          })
          dispatch({ type: ON_ERROR, error: "Invalid response" });
        }
      } catch (error) {
        Toast.show({
          accessible: true,
          accessibilityLabel: t("profile.edit.failed"),
          duration: 8000,
          render: () => <View bgColor="orange" style={{ padding: 10 }}>
            <Text color="white">{t("profile.edit.failed")}</Text>
          </View>
        })
        console.warn("Failed updating user data", error.response.data);
        dispatch({ type: ON_ERROR, error });
      }
    },
    [state.data]
  );

  const value: CardHolderContextValue = React.useMemo(
    () => [
      state,
      { updateStatus, updatePhoto, updateData, reload: requestCardHolderInfo, requestSwitchCardHolderInfo, updateCardHolderData },
    ],
    [state, updateStatus, updateData, requestCardHolderInfo, requestSwitchCardHolderInfo, updateCardHolderData]
  );

  return (
    <CardHolderContext.Provider value={value}>
      {children}
    </CardHolderContext.Provider>
  );
};

export default CardHolderProvider;
